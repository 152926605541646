import { pick } from 'lodash'
import { CockpitContentItem } from '../model/cockpit'

import { ContactLenses, Exam } from '../model/exam'
import {
	PhoropterData,
	PhoropterDataDayOrNight,
	PhoropterDataPart,
	RX_EYES,
} from '../model/instruments'
import {
	Cleaning,
	Monovision,
	PrescriptionComputer,
	PrescriptionContactLenses,
	PrescriptionContactLensesSingleEye,
	PrescriptionData,
	PrescriptionDataArray,
	PrescriptionDataGlasses,
	PrescriptionGeneralUse,
	PrescriptionNight,
	PrescriptionReading,
	PrescriptionSafety,
	PrescriptionSport,
	PrescriptionSunglasses,
	ReplacementFrequency,
} from '../model/prescription'
import { getBlankPhoropterData, hasRefractionData } from './exams'
import { keys } from 'lodash'
import { ConnectRxAddon } from '../model/contentCoreMedia'
import { MyopiaRefraction } from '../model/myopia'
import i18next from 'i18next'
import { VAScale } from '../model/vaScales'

export const replacementFrequencyOptions: ReplacementFrequency[] = [
	'Daily',
	'Weekly',
	'Weeks2',
	'Weeks4',
	'Months2',
	'Months3',
	'Months6',
	'Yearly',
]

export const cleaningOptions: Cleaning[] = [
	'OptifreeReplenish',
	'OptifreePureMoist',
	'Renu',
	'Biotrue',
	'Complete',
	'Revitalens',
	'Clearcare',
	'Aosept',
	'BostonGPSystem',
]

export const monovisionOptions: Monovision[] = [
	'RightEyeDistance',
	'RightEyeNear',
	'LeftEyeDistance',
	'LeftEyeNear',
]

export const lensMaterialOptions = () => [
	{
		label: i18next.t('lensMaterial.cr39'),
		value: 'cr39',
	},
	{
		label: i18next.t('lensMaterial.glass'),
		value: 'glass',
	},
	{
		label: i18next.t('lensMaterial.hi-index'),
		value: 'hi-index',
	},
	{
		label: i18next.t('lensMaterial.polycarbonate'),
		value: 'polycarbonate',
	},
	{
		label: i18next.t('lensMaterial.trivex'),
		value: 'trivex',
	},
]

export function pdfBlobConversion(
	b64Data: string,
	contentType = 'application/pdf',
) {
	contentType = contentType || ''
	var sliceSize = 512
	b64Data = b64Data.replace(/^[^,]+,/, '')
	b64Data = b64Data.replace(/\s/g, '')
	var byteCharacters = window.atob(b64Data)
	var byteArrays = []

	for (
		var offset = 0;
		offset < byteCharacters.length;
		offset = offset + sliceSize
	) {
		var slice = byteCharacters.slice(offset, offset + sliceSize)

		var byteNumbers = new Array(slice.length)
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}

		var byteArray = new Uint8Array(byteNumbers)

		byteArrays.push(byteArray)
	}

	var blob = new Blob(byteArrays, { type: contentType })
	return blob
}

export const convertPhDataForLensData = (
	phData: PhoropterDataDayOrNight,
	vaScale: VAScale,
	refractionData?: PhoropterDataPart,
) => {
	return {
		LensData: {
			default: {
				accuracy: phData.default.accuracy,
				...phData.default.eyes,
			},
			other: phData.other
				? {
						accuracy: phData.other.accuracy,
						...phData.other.eyes,
				  }
				: undefined,
			...convertRefractionDataPhAndGlareForLensData(vaScale, refractionData),
		},
	}
}

const convertRefractionDataPhAndGlareForLensData = (
	vaScale: VAScale,
	refractionData?: PhoropterDataPart,
) => {
	const vaLabel = i18next.t(`exam.vaScaleLabel.${vaScale}`)
	const emptyLRB = {
		L: '',
		R: '',
		B: '',
	}
	return !refractionData
		? {
				ph: {
					...emptyLRB,
				},
				glare: {
					...emptyLRB,
				},
		  }
		: {
				ph: refractionData.ph
					? {
							...{
								L: refractionData.ph.L
									? `${vaLabel}${refractionData.ph.L}`
									: '',
								R: refractionData.ph.R
									? `${vaLabel}${refractionData.ph.R}`
									: '',
								B: refractionData.ph.B
									? `${vaLabel}${refractionData.ph.B}`
									: '',
							},
					  }
					: {
							...emptyLRB,
					  },
				glare: refractionData.glare
					? {
							...refractionData.glare,
					  }
					: {
							...emptyLRB,
					  },
		  }
}

export const lensDataDefaultValues = {
	add: '',
	axis: '',
	cyl: '',
	description: '',
	hOrient: '',
	hPrism: '',
	sphere: '',
	vOrient: '',
	vPrism: '',
}

export const lensDataSingleEyeDefaultValues = {
	LensType: '',
	Measure: {
		baseCurve: '',
		diameter: '',
		sphere: '',
		cylinder: '',
		axis: '',
		addition: '',
		baseCurve2: '',
		diameter2: '',
		sphere2: '',
		asphericity: '',
		secondaryCurveRadius: '',
		secondaryCurveRadius2: '',
		secondaryCurveWidth: '',
		secondaryCurveWidth2: '',
		peripheralCurveRadius: '',
		peripheralCurveWidth: '',
		skirtCurve: '',
		centerThickness: '',
		edgeThickness: '',
		edgeBevel: '',
		segmentHeight: '',
		segmentWidth: '',
		blend: '',
		plasmaCoating: '',
		sagittal: '',
		dot: false,
	},
	ReplacementFrequency: [],
	Cleaning: [],

	LensProduct: '',
	Manufacter: '',
	Monovision: '',
	DisinfectingSolution: '',
	Quantity: '',

	Replenishment: '',
	WearSchedule: '',

	AdditionalParameters: '',
	DesignName: '',
	Material: '',
	Custom: false,
}

export const prescriptionDefaultValues = {
	GeneralUse: {
		LensType: {
			SingleVision: false,
			SingleVisionWithPowerBoost: false,
			Progressive: false,
			ProgressiveForDominantHand: false,
			Bifocal: false,
			Trifocal: false,
		},
		UseInstructions: {
			FullTime: true,
			AsNeeded: false,
			Near: false,
			Distance: false,
		},
		LensRecommendations: {
			HighIndex: false,
			Polycarbonate: true,
			AntiReflective: true,
			Photochromic: false,
			BlueLight: true,
		},
		LensAddOns: [],
		LensDistanceVA: {
			OD: '',
			OS: '',
		},
		LensNearVA: {
			OD: '',
			OS: '',
		},
	},

	Sunglasses: {
		LensType: {
			SingleVision: false,
			Progressive: false,
			Bifocal: false,
			Trifocal: false,
			NonRx: true,
		},
		UseInstructions: {
			AllYear: true,
			Distance: false,
			OverContantLenses: false,
		},
		LensRecommendations: {
			HighIndex: false,
			Polycarbonate: true,
			Polarized: true,
			BacksideAntiReflective: false,
		},
	},
	Computer: {
		LensType: {
			SingleVision: false,
			SingleVisionWithPowerBoost: false,
			ComputerWorkspace: true,
			Progressive: false,
			ProgressiveForDominantHand: false,
			Bifocal: false,
		},
		UseInstructions: {
			Computer: true,
			AsNeeded: false,
		},
		LensRecommendations: {
			HighIndex: false,
			Polycarbonate: true,
			AntiReflective: true,
			Photochromic: false,
			BlueLight: true,
		},
	},
	Reading: {
		LensType: {
			SingleVision: true,
			SingleVisionWithPowerBoost: true,
		},
		UseInstructions: {
			Reading: true,
		},
		LensRecommendations: {
			HighIndex: false,
			Polycarbonate: true,
			AntiReflective: true,
			Photochromic: false,
			BlueLight: true,
		},
	},
	Night: {
		LensType: {
			SingleVision: false,
			SingleVisionWithPowerBoost: false,
			Progressive: false,
			ProgressiveForDominantHand: false,
			Bifocal: false,
			Trifocal: false,
		},
		UseInstructions: {
			AtNight: true,
			Near: false,
			Distance: false,
		},
		LensRecommendations: {
			HighIndex: false,
			Polycarbonate: true,
			AntiReflective: true,
			Photochromic: false,
			BlueLight: false,
		},
	},

	Sport: {
		LensType: {
			SingleVision: false,
			Progressive: false,
			Bifocal: false,
			Trifocal: false,
			NonRx: false,
		},
		UseInstructions: {
			AsNeeded: false,
			Distance: false,
			OverContanctLenses: false,
		},
		LensRecommendations: {
			HighIndex: false,
			Polycarbonate: true,
			Polarized: false,
			BacksideAntiReflective: true,
		},
	},
	Safety: {
		LensType: {
			SingleVision: false,
			SingleVisionWithPowerBoost: false,
			Progressive: false,
			ProgressiveForDominantHand: false,
			Bifocal: false,
			Trifocal: false,
		},
		UseInstructions: {
			FullTime: false,
			AsNeeded: true,
			Near: false,
			Distance: false,
		},
		LensRecommendations: {
			ImpactResistant: false,
			HighIndex: false,
			Polycarbonate: true,
			AntiReflective: true,
			Photochromic: false,
			BlueLight: false,
		},
	},
	ContactLenses: {
		OD: { ...lensDataSingleEyeDefaultValues },
		OS: { ...lensDataSingleEyeDefaultValues },
		useSameProduct: true,
		selectedEyes: 'BIN' as RX_EYES,
		custom: false,
	},
	Legal: {
		ExpirationDateGlassesLensRx: null,
		ExpirationDateContactLensRx: null,
		ApprovedforContactLenses: false,
	},
}

const mapClEyeDataToClPrescription = (
	eye: 'OD' | 'OS',
	contactLenses: ContactLenses,
) => {
	return {
		...prescriptionDefaultValues.ContactLenses[eye],
		LensType: contactLenses.lensType?.[eye],
		Measure: contactLenses.measure?.[eye],
		AdditionalParameters: contactLenses.additionalParameters?.[eye],
		LensProduct: contactLenses.catalogueId?.[eye],
		SuggestedLensProduct: contactLenses.suggestedCatalogueId?.[eye],
		Manufacter: contactLenses.manufacturer?.[eye],
		Monovision: contactLenses.monovision?.[eye],
		WearSchedule: contactLenses.wearSchedule?.[eye],
		DisinfectingSolution: contactLenses.disinfectingSolution?.[eye],
		Replenishment: contactLenses.replenishment?.[eye],
		Quantity: contactLenses.quantity?.[eye],
		DesignName: contactLenses.designName?.[eye],
		Material: contactLenses.material?.[eye],
	} as PrescriptionContactLensesSingleEye
}

export const convertClDataForLensData = (
	clData: ContactLenses[],
): PrescriptionContactLenses[] => {
	return clData.map(cl => {
		const isBinEye = cl.selectedEye === 'BIN'
		const isCustomCL = !!cl.custom
		const isSameStandardProduct =
			!isCustomCL && cl.catalogueId.OD === cl.catalogueId.OS
		const isSameCustomProduct = isCustomCL && cl.lensType.OD === cl.lensType.OS
		const isSameProduct =
			isBinEye && (isSameStandardProduct || isSameCustomProduct)

		const base: PrescriptionContactLenses = {
			OD: mapClEyeDataToClPrescription('OD', cl),
			OS: mapClEyeDataToClPrescription('OS', cl),
			useSameProduct: isSameProduct,
			selectedEyes: cl.selectedEye,
			fromExam: true,
			custom: cl.custom,
		}
		return base
	})
}

export const prescriptionInitialValues = (
	vaScale: VAScale,
	phData?: PhoropterData,
	clData?: ContactLenses[],
): PrescriptionData => {
	return {
		GeneralUse: phData
			? [
					{
						...prescriptionDefaultValues.GeneralUse,
						...convertPhDataForLensData(
							(hasRefractionData(phData.finalRefraction?.day)
								? phData.finalRefraction?.day
								: false) ||
								(hasRefractionData(phData.subjectiveRefraction?.day)
									? phData.subjectiveRefraction?.day
									: false) ||
								getBlankPhoropterData().finalRefraction.day,
							vaScale,
						),
						SelectedEye: 'BIN',
					},
			  ]
			: ([] as PrescriptionGeneralUse[]),
		Sunglasses: [] as PrescriptionSunglasses[],
		Computer: [] as PrescriptionComputer[],
		Night: [] as PrescriptionNight[],
		Reading: [] as PrescriptionReading[],
		Sport: [] as PrescriptionSport[],
		Safety: [] as PrescriptionSafety[],
		ContactLenses:
			clData?.length !== 0
				? convertClDataForLensData(clData || [])
				: ([] as PrescriptionContactLenses[]),
		Legal: {
			ExpirationDateGlassesLensRx: null,
			ExpirationDateContactLensRx: null,
			ApprovedforContactLenses: false,
		},
	}
}

export const isPopulated = (val: string | boolean | object) =>
	val === null || val === undefined
		? false
		: typeof val === 'object'
		? Object.values(val).some(isPopulated)
		: Boolean(val)

export const glassesKeys: (keyof PrescriptionDataGlasses)[] = [
	'GeneralUse',
	'Sunglasses',
	'Computer',
	'Reading',
	'Night',
	'Sport',
	'Safety',
]

export const prescriptionDataKeys: (keyof PrescriptionDataArray)[] = [
	'GeneralUse',
	'Sunglasses',
	'Computer',
	'Reading',
	'Night',
	'Sport',
	'Safety',
	'ContactLenses',
]

export const glassesSectionsFilledIn = (
	prescriptionData: PrescriptionData,
): boolean => {
	const glassesData = pick(prescriptionData, glassesKeys)
	const glassesDataArray = Object.values(glassesData)
	return glassesDataArray.some(glDataArray => glDataArray.some(isPopulated))
}

export const contactLensSectionsFilledIn = (
	clData: PrescriptionContactLenses,
): boolean =>
	clData.useSameProduct
		? clData.OD.ReplacementFrequency.length > 0
		: clData.OD.ReplacementFrequency.length > 0 &&
		  clData.OS.ReplacementFrequency.length > 0

export const isDateBeforeToday = (date: Date) =>
	new Date(date) < new Date(new Date().toDateString())

export const isValidDate = (date: Date | string) =>
	!isNaN(typeof date === 'string' ? new Date(date).getTime() : date.getTime())

export const getSelectedTypeAssets = (
	selectedLensTypes: any,
	lensTypes: ConnectRxAddon,
): CockpitContentItem[] =>
	keys(selectedLensTypes)
		.map(
			key =>
				selectedLensTypes[key as keyof typeof selectedLensTypes] && {
					label: lensTypes[key].value,
					value: lensTypes[key].media.videos[0],
					type: 'video',
				},
		)
		.filter(a => a)

export const getSelectedRecommendationAssets = (
	selecedRecommendations: any,
	lensRecommendations: ConnectRxAddon,
): CockpitContentItem[] =>
	keys(selecedRecommendations)
		.map(
			key =>
				selecedRecommendations[key as keyof typeof selecedRecommendations] && {
					label: lensRecommendations[key].value,
					value: lensRecommendations[key].media.videos[0],
					type: 'video',
				},
		)
		.filter(a => a)

export const getPrescriptionInitialValues = (
	exam: Exam,
	previousExam?: Exam,
): PrescriptionData =>
	prescriptionInitialValues(
		exam.vaScaleUsed,
		previousExam
			? previousExam.preTest.instruments.phoropter
			: exam.preTest.instruments.phoropter,
		previousExam && !exam?.contactLensesTrial?.length
			? previousExam.contactLensesTrial?.filter(
					cl => cl.status === 'PRESCRIBED',
			  )
			: exam?.contactLensesTrial?.filter(cl => cl.status === 'PRESCRIBED'),
	)

export const getGlareAndPhFromPhoropterData = (
	vaScale: VAScale,
	phoropterData?: PhoropterData,
) =>
	phoropterData
		? convertRefractionDataPhAndGlareForLensData(
				vaScale,
				phoropterData.finalRefraction || phoropterData.subjectiveRefraction,
		  )
		: {
				glare: {
					L: '',
					R: '',
					B: '',
				},
				ph: {
					L: '',
					R: '',
					B: '',
				},
		  }

export const getMyopiaRefraction = (
	phoropter: PhoropterData,
): MyopiaRefraction => {
	const data =
		phoropter.finalRefraction?.day?.default?.eyes ??
		phoropter.subjectiveRefraction?.day?.default?.eyes
	return {
		R: {
			sphere: data?.OD?.sphere || '',
			cyl: data?.OD?.cyl || '',
			axis: data?.OD?.axis || '',
			add: data?.OD?.add || '',
			hPrism: data?.OD?.hPrism || '',
			vPrism: data?.OD?.vPrism || '',
			hOrient: data?.OD?.hOrient || '',
			vOrient: data?.OD?.vOrient || '',
		},
		L: {
			sphere: data?.OS?.sphere || '',
			cyl: data?.OS?.cyl || '',
			axis: data?.OS?.axis || '',
			add: data?.OS?.add || '',
			hPrism: data?.OS?.hPrism || '',
			vPrism: data?.OS?.vPrism || '',
			hOrient: data?.OS?.hOrient || '',
			vOrient: data?.OS?.vOrient || '',
		},
		sensitivity: phoropter.sensitivity,
	}
}
